

export default
{
    name: "torntext",
    props: {
        content: Object
    }
}

